import { UPDATE_APP_DATA } from 'constants/action_types'
import { selectCurrentTotalPerCycle } from 'ducks/billing/selectors/selectCurrentTotalPerCycle'
import {
  SHOPIFY_BILLING_CONFIRMED,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from 'ducks/billing/types'
import { FETCH_CURRENT_USER_SUCCESS } from 'ducks/currentUser/types'
import { createActionTypeReducer } from 'util/reducers'
import { dataLayer } from 'util/tracking'
import { capture, record } from 'ducks/tracking/actions'
import { debounce } from 'util/functions'

const identifyUser = (_, { data: { currentUser: user } }) => {
  dataLayer({ event: 'identifyUser', userId: user.id })
}

const updateAppDataReducer = (state, action) => {
  identifyUser(state, action)
  if (action?.data?.billing?.inbox?.state === 'trial') {
    record()
  }
}

const trackConversion = (_, action, sharedState) => {
  // We dont want to track changes when moving between different trial plans
  if (
    action.type === UPDATE_SUBSCRIPTION_SUCCESS &&
    action.payload?.updateSubscription?.state === 'trial'
  )
    return

  const currentTotalPerCycle = selectCurrentTotalPerCycle(sharedState)
  const account = sharedState.app.account
  const first = !account?.preferences?.usage_onboarding?.activated_subscription
  const {
    billing_cycle: billingCycle = sharedState.billing.billing_cycle,
    change_type: changeType = 'na',
    pricing_ids: pricingIds = '',
    was_trial: wasTrial = false,
  } =
    action.meta || {}
  dataLayer({
    event: 'billingChange',
    accountId: account?.id,
    externalAccountId: sharedState.billing.externalAccountId,
    provider: sharedState.billing.provider,
    billingCycle: sharedState.billing.billing_cycle,
    currentTotalPerCycle,
    isFirstConversion: first,
  })
  capture(`billing subscription update`, {
    external_account_id: sharedState.billing.externalAccountId,
    provider: sharedState.billing.provider,
    billing_cycle: billingCycle,
    current_total_per_cycle: currentTotalPerCycle,
    is_first_conversion: first,
    change_type: changeType,
    pricing_ids: pricingIds,
    // This is a little confusing, but we can only reach this event
    // if the returned subscription doesnt have a state of trial. This means
    // if the subscription was a trial when it was updated and is no longer
    // a trial, then this is a trial conversion
    is_trial_conversion: wasTrial,
  })
}

// Kevin R: 2023-08-23
// Debouncing here prevents siguations where we capture multiple pages views
// for the same page caused by certian loading functions modifying the query
// string while the page loads up. Its not a perfect solution, but it'll work
// good enough
const debouncedCapture = debounce(capture, 1000)

const trackPageView = (_, action) => {
  if (action.type.startsWith('onboarding/') && action.type.endsWith('_PAGE')) {
    debouncedCapture('$pageview')
  }
}

export default createActionTypeReducer(
  {
    [UPDATE_APP_DATA]: updateAppDataReducer,
    [FETCH_CURRENT_USER_SUCCESS]: identifyUser,
    [SHOPIFY_BILLING_CONFIRMED]: trackConversion,
    [UPDATE_SUBSCRIPTION_SUCCESS]: (draftState, action, sharedState) => {
      // NOTE (jscheel): Shopify fires this action, but we don't actually know
      // that the customer has successfully set up a new subscription yet,
      if (sharedState.billing.provider === 'shopify') return draftState
      return trackConversion(draftState, action, sharedState)
    },
    '*': trackPageView,
  },
  {}
)
