import createCachedSelector from 're-reselect'
import { all, any, uniq } from 'util/arrays'
import { createArraySelector } from 'util/selectors'

import gaugeCacheKey from './cacheKeys/gaugeCacheKey'
import extractXValuesFromJoinedDataSet from './extractXValuesFromJoinedDataSet'
import generateAndDecorateDataSetsForGauge from './generateAndDecorateDataSetsForGauge'
import selectDecoratedPreviousQueries from './selectDecoratedPreviousQueries'
import selectDecoratedQueries from './selectDecoratedQueries'
import sortAndPageDataSets from './sortAndPageDataSets'

const selectDecoratedGauge = createCachedSelector(
  selectDecoratedPreviousQueries,
  selectDecoratedQueries,
  (state, { decoratedJoinedThroughGauge }) => decoratedJoinedThroughGauge,
  (state, { decoratedSortedGauge }) => decoratedSortedGauge,
  (state, { includePrevious }) => includePrevious,
  (state, { join: { through } = {} }) => through,
  (state, { gauge }) => gauge,
  (state, { gauge: { id } }) => id,
  (state, { gauge: { sort: { method } = {} } }) => method,
  (state, { nullIsValidX }) => nullIsValidX,
  (state, { pagination: { page } = {} }) => page,
  (state, { pagination: { perPage } = {} }) => perPage,
  (state, { sort: { by } = {} }) => by,
  (state, { sort: { direction } = {} }) => direction,
  (state, { xValueTranslations }) => xValueTranslations,
  (
    decoratedPreviousQueries,
    decoratedQueries,
    decoratedJoinedThroughGauge,
    decoratedSortedGauge,
    includePrevious,
    joinThrough,
    gauge,
    id,
    sortMethod,
    nullIsValidX,
    page,
    perPage,
    sortBy,
    sortDirection,
    xValueTranslations
  ) => {
    const { loaded: decoratedJoinedThroughGaugeLoaded } =
      decoratedJoinedThroughGauge || {}
    const { loaded: decoratedSortedGaugeLoaded } = decoratedSortedGauge || {}
    const isSorted = id === sortBy
    const isJoinedThrough = id === joinThrough
    const isSortedLoaded = decoratedSortedGaugeLoaded
    const isJoinedThroughLoaded = decoratedJoinedThroughGaugeLoaded
    const loadable =
      (isSortedLoaded && isJoinedThroughLoaded) || isJoinedThrough || isSorted
    const joinedThroughDataSet =
      decoratedJoinedThroughGauge &&
      decoratedJoinedThroughGauge.dataSets.find(({ isJoinedThrough: x }) => x)
    const sortedDataSet =
      decoratedSortedGauge &&
      decoratedSortedGauge.dataSets.find(({ isSorted: x }) => x)
    const {
      xValues: defaultUnpagedXValues = [], // If there are no unpagedXValues paging wasn't applied and we can just use xValues
      unpagedXValues: joinedXValues = defaultUnpagedXValues,
    } =
      joinedThroughDataSet || {}
    const {
      totalCount: sortedTotalCount,
      xValues: defaultSortedUnpagedXValues = [], // If there are no unpagedXValues paging wasn't applied and we can just use xValues
      unpagedXValues: sortedXValues = defaultSortedUnpagedXValues,
    } =
      sortedDataSet || {}
    const totalCount = any(q => q.loaded, decoratedQueries)
      ? Math.max(...decoratedQueries.map(q => q.totalCount))
      : null

    const decoratedGauge = {
      ...gauge,
      errored: any(q => q.errored, decoratedQueries),
      includePrevious,
      isJoinedThrough,
      isSorted,
      loadable,
      loaded: all(q => q.loaded, decoratedQueries),
      loading: any(q => q.loading, decoratedQueries),
      nullIsValidX,
      queries: decoratedQueries,
      ...(includePrevious
        ? {
            previousErrored: any(q => q.errored, decoratedPreviousQueries),
            previousLoaded: all(q => q.loaded, decoratedPreviousQueries),
            previousLoading: all(q => q.loading, decoratedPreviousQueries),
            previousQueries: decoratedPreviousQueries,
          }
        : {}),
      totalCount,
      undecoratedGauge: gauge,
      xValueTranslations,
    }
    const sortedJoinedXValues = !isSorted
      ? !isJoinedThrough &&
        uniq((sortedXValues || []).concat(joinedXValues || []))
      : joinedXValues
    decoratedGauge.dataSets = sortAndPageDataSets(
      generateAndDecorateDataSetsForGauge(decoratedGauge, sortBy),
      sortedJoinedXValues,
      page,
      perPage,
      sortBy,
      sortDirection,
      sortMethod,
      sortedTotalCount
    )
    decoratedGauge.xValues = extractXValuesFromJoinedDataSet(
      decoratedGauge.dataSets
    )
    decoratedGauge.unpagedXValues = extractXValuesFromJoinedDataSet(
      decoratedGauge.dataSets,
      true
    )
    return decoratedGauge
  }
)(gaugeCacheKey)
export default selectDecoratedGauge
