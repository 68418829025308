import { createPayload } from 'optimistic/base'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { selectLabelsById } from 'selectors/labels'
import { merge } from 'util/labels'
import { optimisticUpdatedAt } from 'util/optimistic'

// builds a mock GraphQL response of a createChangeset mutation for a
// ticket label change
export const labelChangePayload = (
  state,
  ticketId,
  addLabels = [],
  removeLabels = [],
  folderId,
  search,
  changesetId = null
) => {
  const ticket = selectTicketsById(state)[ticketId] || {}
  const labelsById = selectLabelsById(state) || {}
  // compute what ticket.labels would look like after applying these changes
  const ticketLabelIds = ticket.labels.map(x => x.id || x.name)
  const addLabelIds = addLabels.map(x => x.id || x.name)
  const removeLabelIds = removeLabels.map(x => x.id || x.name)
  const labels = merge(ticketLabelIds, addLabelIds, removeLabelIds)

  const payload = createPayload(
    state,
    ticketId,
    'label',
    {
      labels: labels.map(labelId => {
        return labelsById[labelId] || { id: labelId, name: labelId }
      }),
    },
    // At time of writing, we dont expose a label change action via the API,
    // However we create a dummy one here for other components/functions that
    // expect actions.
    [
      {
        change_type: 'Label',
        change: {},
        //   label,
        //   type: 'Label',
        //   delta: 1,
        // },
      },
    ],
    folderId,
    search,
    changesetId
  )

  if (!payload.diff) {
    const mailboxIds = [null, ticket.mailboxId]
    const searches = {}
    payload.diff = { searches }
    addLabelIds.forEach(id => {
      mailboxIds.forEach(mailboxId => {
        const base = mailboxId ? `inbox:${mailboxId} tag:${id}` : `tag:${id}`
        searches[base] = 1
        if (ticket.state === 'opened') searches[`${base} is:open`] = 1
        if (ticket.state === 'closed') searches[`${base} is:closed`] = 1
      })
    })
    removeLabelIds.forEach(id => {
      mailboxIds.forEach(mailboxId => {
        const base = mailboxId ? `inbox:${mailboxId} tag:${id}` : `tag:${id}`
        searches[base] = -1
        if (ticket.state === 'opened') searches[`${base} is:open`] = -1
        if (ticket.state === 'closed') searches[`${base} is:closed`] = -1
      })
    })
  }
  return payload
}

// Returns the optimistic data for a bulk label change
export const bulkLabelChangePayload = (
  state,
  inputs,
  folderId,
  search,
  changesetId = null
) => {
  return inputs.map(input => {
    return labelChangePayload(
      state,
      input.ticketId,
      input.labels,
      input.removeLabels,
      folderId,
      search,
      changesetId,
      optimisticUpdatedAt()
    )
  })
}
