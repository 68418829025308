import { combineReducers } from 'core/redux'

import {
  FETCH_REPORT_QUERIES_REQUEST,
  FETCH_REPORT_QUERIES_RESPONSE,
  FETCH_REPORT_QUERIES_ERROR,
  FETCH_REPORT_QUERY_REQUEST,
  FETCH_REPORT_QUERY_RESPONSE,
  FETCH_REPORT_QUERY_ERROR,
} from './types'

const defaultById = {}

function byId(
  state,
  {
    type,
    payload: {
      result,
      pagination,
      queries,
      query: singleQuery,
      queryId: singleQueryId,
      queryIds,
    } = {},
  }
) {
  const existingQuery = state && state[singleQueryId]
  switch (type) {
    case FETCH_REPORT_QUERIES_REQUEST: {
      const newState = { ...state }
      queryIds.forEach((queryId, index) => {
        newState[queryId] = {
          ...newState[queryId],
          ...queries[index],
          errored: false,
          loaded: false,
          loading: true,
        }
      })
      return newState
    }

    case FETCH_REPORT_QUERIES_RESPONSE: {
      const newState = { ...state }
      queryIds.forEach((queryId, index) => {
        const { error, result: queryResult } = result[index]
        if (error) {
          newState[queryId] = {
            ...newState[queryId],
            errored: true,
            loading: false,
          }
        } else {
          newState[queryId] = {
            ...newState[queryId],
            errored: false,
            loaded: true,
            loading: false,
            result: queryResult,
          }
        }
      })
      return newState
    }

    case FETCH_REPORT_QUERIES_ERROR: {
      const newState = { ...state }
      queryIds.forEach(queryId => {
        newState[queryId] = {
          ...newState[queryId],
          errored: true,
          loading: false,
        }
      })
      return newState
    }

    case FETCH_REPORT_QUERY_REQUEST: {
      return {
        ...state,
        [singleQueryId]: {
          ...existingQuery,
          ...singleQuery,
          errored: false,
          loaded: false,
          loading: true,
        },
      }
    }

    case FETCH_REPORT_QUERY_RESPONSE: {
      return {
        ...state,
        [singleQueryId]: {
          ...existingQuery,
          ...singleQuery,
          loading: false,
          loaded: true,
          pagination,
        },
      }
    }

    case FETCH_REPORT_QUERY_ERROR: {
      return {
        ...state,
        [singleQueryId]: {
          ...existingQuery,
          errored: true,
          loading: false,
        },
      }
    }

    default:
      return state || defaultById
  }
}

export default combineReducers({
  byId,
})
