import createCachedSelector from 're-reselect'

import { selectBrowserTimezoneOffset } from 'ducks/reports/controls'
import { selectQueriesByIdRaw } from 'ducks/reports/queries'
import { emptyObj } from 'util/objects'

import adjustResultTimeframesToUTC from './adjustResultTimeframesToUTC'
import adjustTimeframeToPreviousPeriod from './adjustTimeframeToPreviousPeriod'
import applyIdToQuery from './applyIdToQuery'
import applyJoinThroughScopeToQuery from './applyJoinThroughScopeToQuery'
import applyQueryBaseToQuery from './applyQueryBaseToQuery'
import applyPaginationAndSortingToQuery from './applyPaginationAndSortingToQuery'
import applyScopeToQuery from './applyScopeToQuery'
import applyStateToQuery from './applyStateToQuery'
import applyXValuesFilterToQuery from './applyXValuesFilterToQuery'
import queryCacheKey from './cacheKeys/queryCacheKey'

export default createCachedSelector(
  selectBrowserTimezoneOffset,
  (state, { gauge: { id } }) => id,
  (state, { isSorted }) => isSorted,
  (state, { joinedXValues }) => joinedXValues,
  (state, { loadable }) => loadable,
  (state, { pagination }) => pagination,
  (state, { previous }) => previous,
  (state, { queryBase = emptyObj }) => queryBase,
  (state, { query }) => query,
  (state, { scope = emptyObj }) => scope,
  (state, { sort }) => sort,
  (state, { xValues }) => xValues,
  (
    offset,
    id,
    isSorted,
    joinedXValues,
    loadable,
    pagination,
    previous,
    queryBase,
    query,
    scope,
    sort,
    xValues
  ) => {
    const queryWithBase = applyQueryBaseToQuery(query, queryBase, scope)

    let decoratedQuery = queryWithBase
    if (previous) {
      decoratedQuery = adjustTimeframeToPreviousPeriod(decoratedQuery)
    }
    decoratedQuery = applyScopeToQuery(decoratedQuery)
    decoratedQuery = applyPaginationAndSortingToQuery(
      decoratedQuery,
      pagination,
      sort
    )
    decoratedQuery = applyJoinThroughScopeToQuery(
      decoratedQuery,
      isSorted && query.loader,
      joinedXValues,
      loadable,
      pagination
    )

    if (xValues)
      decoratedQuery = applyXValuesFilterToQuery(decoratedQuery, xValues)
    return applyIdToQuery(decoratedQuery)
  }
)(queryCacheKey)
